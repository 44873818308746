import { ChangeDetectionStrategy, Component, NgZone, OnInit } from '@angular/core';
import { BehaviorSubject, concatMap, delay, filter, from, of, tap, } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Storage } from '@shared/storage.service';
import { Notification } from '@shared/interfaces/notification.interface';
import packageJson from '../../package.json';
import { ConferenceCreateInfo } from '@shared/interfaces/conference.interface';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from '@shared/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  appVersion: string = packageJson.version;
  isLoading$: BehaviorSubject<boolean> = this.storage.isLoading$;

  constructor(
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private storage: Storage,
    private authService: AuthService,
  ) {
    console.warn(`Add-in version: ${this.appVersion}`);
  }

  ngOnInit(): void {
    Office.context.mailbox.item.notificationMessages.addAsync('reminder', {
      type: Office.MailboxEnums.ItemNotificationMessageType.ProgressIndicator,
      message: "Please make sure you updated conference via add-in before click 'Save'"
    });

    Office.context.mailbox.item.loadCustomPropertiesAsync((asyncProp: Office.AsyncResult<Office.CustomProperties>) => {
      this.ngZone.run(() => this.storage.customProperties$.next(asyncProp.value));
    });

    from(Office.auth.getAccessToken({
      allowConsentPrompt: true
    })).pipe(
      untilDestroyed(this)
    ).subscribe((vl) => {
      console.log(vl);
      console.log(jwtDecode(vl))
    });

    this.listenCustomPropsChanges();
    this.restoreData();
    this.handleNotifications();
  }

  private listenCustomPropsChanges(): void {
    this.storage.getSaveQueue().pipe(
      untilDestroyed(this)
    ).subscribe();
  }

  private restoreData(): void {
    this.storage.login$.next(Office.context.roamingSettings.get('login'));
    this.storage.MxIp$.next(Office.context.roamingSettings.get('host'));
    this.storage.sessionId$.next(Office.context.roamingSettings.get('sessionId'));
    this.storage.trustedDeviceId$.next(Office.context.roamingSettings.get('trustedDeviceId'));
    this.storage.trustedDeviceCode$.next(Office.context.roamingSettings.get('trustedDeviceCode'));

    this.storage.customProperties$.pipe(
      filter(Boolean),
      tap((value: Office.CustomProperties) => {
        const conferenceInfo: ConferenceCreateInfo = JSON.parse(value.get('conferenceInfo') || '{}');

        this.storage.conferenceInfo$.next(conferenceInfo);
        this.storage.conferenceId$.next(value.get('conferenceId'));

        if (this.storage.sessionId$.getValue()) {
          this.authService.login(this.storage.MxIp$.getValue(), this.storage.login$.getValue());
        }
      }),
      untilDestroyed(this)
    ).subscribe(() => this.storage.isLoading$.next(false));
  }

  private handleNotifications(): void {
    this.storage.notificationsList$.pipe(
      filter(Boolean),
      concatMap((notification: Notification) => of(notification).pipe(
        tap(() => this.snackBar.open(notification.message, null, {
          duration: 3000,
          panelClass: notification.type
        })),
        delay(3000),
      )),
      untilDestroyed(this)
    ).subscribe();
  }
}
