import { Injectable, NgZone } from '@angular/core';
import { Storage } from '@shared/storage.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { RequestType } from '../modules/auth/components/enums/request-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { NotificationType } from '@shared/enums/notification-type.enum';
import { Observable, skip, tap, timer } from 'rxjs';

const FAILED_LOGIN: string = 'loginFailed';
const MFA_FLAG_TEXT: string = 'One time code authentication required';
const MFA_TRUSTED_FLAG_TEXT: string = 'Trusted device authentication required';
const KEEP_ALIVE_INTERVAL: number = 30000;

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  invokeId = 0;

  webSocket$: WebSocketSubject<any>;

  constructor(
    private storage: Storage,
    private router: Router,
    private ngZone: NgZone
  ) { }

  login(host: string, login?: string, password?: string): void {
    this.storage.isLoading$.next(true);
    this.webSocket$ = webSocket({
      url: `wss://${host}:7779`,
      serializer: (value: any) => value,
      deserializer: (event: MessageEvent<string>) => this.deserialize(event.data, host, login)
    });
    const request: string = this.createRequest(RequestType.LOGIN, login, password);

    this.webSocket$.pipe(
      untilDestroyed(this)
    ).subscribe({
      error: (err) => {
        if (err.type === 'error') {
          this.storage.notificationsList$.next({
            message: `${err.target.readyState === WebSocket.CLOSED
              ? 'Failed to establish WebSocket connection. Please check your network settings or certificates.'
              : 'WebSocket connection error occurred'}`,
            type: NotificationType.ERROR
          });
        }

        this.storage.isLoading$.next(false);
      },
      complete: () => console.warn('Connection closed')
    });

    this.webSocket$.next(request);
  }

  logout(): void {
    this.storage.isLoading$.next(true);

    Office.context.roamingSettings.remove('sessionId');
    Office.context.roamingSettings.remove('login');
    Office.context.roamingSettings.remove('host');
    Office.context.roamingSettings.remove('trustedDeviceId');
    Office.context.roamingSettings.remove('trustedDeviceCode');
    Office.context.roamingSettings.saveAsync((result: Office.AsyncResult<void>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        this.ngZone.run(() => {
          this.storage.login$.next(null);
          this.storage.MxIp$.next(null);
          this.storage.sessionId$.next(null);
          this.storage.trustedDeviceId$.next(null);
          this.storage.trustedDeviceCode$.next(null);

          const request: string = this.createRequest(RequestType.LOGOUT);
          this.webSocket$.next(request);
          this.webSocket$.complete();

          this.router.navigate(['login']).then(() => this.storage.isLoading$.next(false));
        });
      }
    });
  }

  keepAliveSession(): Observable<number> {
    return timer(0, KEEP_ALIVE_INTERVAL).pipe(
      skip(1),
      tap(() => this.webSocket$.next(this.createRequest(RequestType.KEEPALIVE))),
    )
  }

  private createRequest(type: RequestType, login?: string, password?: string): string {
    const data: string = this.serializeCommonRequest(type, login, password);
    const header: string = this.serializeHeader(data.length);

    return `${header}${data}`;
  }

  private serializeCommonRequest(type: RequestType, login?: string, password?: string): string {
    const sessionId: string = this.storage.sessionId$.getValue();

    switch (type) {
      case RequestType.LOGIN:
        return `<?xml version='1.0' encoding='UTF-8'?><loginRequest type='User' platform='Outlook' version='3.0.13.0' clientType='Desktop' persist='true' abNotify='true' apiVersion='11' forced='false' loginCapab='Audio|Video|Im|911Support|WebChat|ScreenSharing|VideoConf|SchedConf|HttpFileTransfer|ConfGroup|Switchover|Mfa' mediaCapab='Voicemail|Fax|CallRec' dcmode='phone' webToken='${sessionId || ''}' loginType='Ordinal' trustedDeviceId='${this.storage.trustedDeviceId$.getValue() || ''}'>`
          + `<userName>${login || ''}</userName><pwd>${password || ''}</pwd>${sessionId ? `<webSession>${sessionId}</webSession>` : ''}`
          + '</loginRequest>';
      case RequestType.LOGOUT:
        return '<?xml version="1.0" encoding="UTF-8"?><logout></logout>';
      case RequestType.KEEPALIVE:
        return '<?xml version="1.0" encoding="UTF-8"?><keepalive/>'
      default:
        return '';
    }
  }

  private serializeHeader(dataLength: number): string {
    let header = '';
    header += String.fromCharCode(0);
    header += String.fromCharCode(0);
    const dataLen = dataLength + 8;
    const len1 = Math.floor(dataLen / 256);
    const len2 = Math.floor(dataLen - len1 * 256);
    header += String.fromCharCode(len1);
    header += String.fromCharCode(len2);
    const invokeId = this.invokeId.toString();
    const len = invokeId.length;
    if (len < 4) {
      for (let i = 0; i < 4 - len; i++) {
        header += String.fromCharCode(0x30);
      }
    }
    for (let i = 0; i < len; i++) {
      header += invokeId[i];
    }

    const headerB64 = btoa(header);
    let encodedHeader = String.fromCharCode(headerB64.length);
    encodedHeader += headerB64;

    this.invokeId++;

    if (this.invokeId === 9999) {
      this.invokeId = 0;
    }

    return encodedHeader;
  }

  private deserialize(data: string, host: string, login: string): void {
    const document: Document = new DOMParser().parseFromString(data.replace(/^.*?\w+=/, ''), 'text/xml');
    const node: ChildNode = document.childNodes.item(0);
    const sessionId: string = (node as any)?.attributes?.getNamedItem('wwwUuid')?.value;
    const child: ChildNode | undefined = node.childNodes.item(0)?.childNodes?.item(0);

    if (node.nodeName === FAILED_LOGIN) {
      if (node.textContent === MFA_FLAG_TEXT || node.textContent === MFA_TRUSTED_FLAG_TEXT) {
        this.handleMultiFactorAuth(node, sessionId, host);
        return;
      }

      this.storage.notificationsList$.next({ message: node.textContent, type: NotificationType.ERROR });
      this.router.navigate(['login']).then(() => this.storage.isLoading$.next(false));
      return;
    }

    if (node.nodeName === 'ConfAddEvent' && child?.nodeName === 'confId' && this.storage.isRequestInProgress$.getValue()) {
      this.storage.conferenceId$.next(child.textContent);

      this.storage.setCustomProperty('conferenceId', child.textContent);
      this.storage.saveCustomProperties();

      this.storage.isRequestInProgress$.next(false);
    }

    if (sessionId) {
      Office.context.roamingSettings.set('sessionId', sessionId);
      Office.context.roamingSettings.set('login', login);
      Office.context.roamingSettings.set('host', host);
      Office.context.roamingSettings.saveAsync();

      this.storage.sessionId$.next(sessionId);
      this.storage.login$.next(login);
      this.storage.MxIp$.next(host);

      this.router.navigate(['conference']);
    }

    this.storage.isLoading$.next(false);
  }

  private handleMultiFactorAuth(node: ChildNode, sessionId: string, host: string): void {
    const authSessionToken = (node as any)?.attributes?.getNamedItem('authSessionToken')?.value;
    const deliveryChannel = (node as any)?.attributes?.getNamedItem('otcDeliveryChannel')?.value;
    const otcLength = (node as any)?.attributes?.getNamedItem('otcLength')?.value;
    const otcResendDelay = (node as any)?.attributes?.getNamedItem('otcResendDelay')?.value;
    const trustedDeviceMode = (node as any)?.attributes?.getNamedItem('trustedDeviceMode')?.value;
    const trustedDeviceLifeTime = (node as any)?.attributes?.getNamedItem('trustedDeviceLifeTime')?.value;

    this.storage.multiFactorData$.next({
      authSessionToken,
      deliveryChannel,
      otcLength: Number(otcLength),
      otcResendDelay: Number(otcResendDelay),
      trustedDeviceMode,
      trustedDeviceLifeTime: Number(trustedDeviceLifeTime)
    });

    if (sessionId) {
      this.storage.sessionId$.next(sessionId);
      this.storage.MxIp$.next(host);
    }

    this.router.navigate(['login', 'mfa']);
    this.storage.isLoading$.next(false);
  }
}
