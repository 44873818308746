import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, concatMap, filter, Observable } from 'rxjs';
import { UserProfile } from '@shared/interfaces/users.interface';
import { ConferenceCreateInfo, ConferenceMember } from '@shared/interfaces/conference.interface';
import { Notification } from '@shared/interfaces/notification.interface';
import { MultiFactorData } from '../modules/auth/interfaces/multi-factor.intercace';

@Injectable({
  providedIn: 'root'
})
export class Storage {
  sessionId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  MxIp$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  login$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  notificationsList$: BehaviorSubject<Notification> = new BehaviorSubject<Notification>(null);
  conferenceId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  trustedDeviceId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  trustedDeviceCode$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isRequestInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  userProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  customProperties$: BehaviorSubject<Office.CustomProperties> = new BehaviorSubject<Office.CustomProperties>(null);
  contactsList$: BehaviorSubject<ConferenceMember[]> = new BehaviorSubject<ConferenceMember[]>([]);
  multiFactorData$: BehaviorSubject<MultiFactorData> = new BehaviorSubject<MultiFactorData>(null);
  conferenceInfo$: BehaviorSubject<ConferenceCreateInfo> = new BehaviorSubject<ConferenceCreateInfo>(null);

  private saveQueue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private ngZone: NgZone) {}

  setCustomProperty(key: string, value: string): void {
    this.customProperties$.getValue().set(key, value);
  }

  removeCustomProperty(key: string): void {
    this.customProperties$.getValue().remove(key);
  }

  saveCustomProperties(): void {
    this.saveQueue$.next(true);
  }

  getSaveQueue(): Observable<void> {
    return this.saveQueue$.pipe(
      filter(Boolean),
      concatMap(() => {
        return new Observable<void>((observer) => {
           this.customProperties$.getValue().saveAsync(() => {
             this.ngZone.run(() => {
               observer.next();
               observer.complete();
             });
           });
        });
      })
    );
  }
}
