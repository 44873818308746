import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, NgOptimizedImage } from '@angular/common';
import { CustomLocationStrategy } from '@shared/custom-location-strategy';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpRequestsInterceptor } from '@shared/http-requests.interceptor';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgOptimizedImage,
    MatProgressSpinnerModule,
    MatSnackBarModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: CustomLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestsInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
